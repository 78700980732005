
<template>
    <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 leftbg">
        <div class="d-flex flex-column   p-2 min-vh-100">


            <a href="/admin901" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white font_semibold">
                ADMIN PANEL
            </a>



            <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">

                <li class="nav-item" :class="{active: $route.name==='Games'}" > 
                    <a href="/admin901/games" class="nav-link align-middle ">
                        <font-awesome-icon icon="ghost" class="color_ok mr-1" /> <span class="ms-1 d-none d-sm-inline color_white">All Games</span>
                    </a>
                </li>
                <li class="nav-item" :class="{active: $route.name==='addGame'}">
                    <a href="/admin901/addGame" class="nav-link align-middle">
                        <font-awesome-icon icon="plus-square" class="color_ok mr-1" /> <span class="ms-1 d-none d-sm-inline color_white">Add Game</span>
                    </a>
                </li>
                
                
            </ul>

        
        <div class="pb-4 text-center">
            Escapist 2022
        </div>
        </div>
    </div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>


export default {

    components:{
        //HeaderBar
    },

    data(){
        return {
        
        }
    },

    methods:{
        
    },

    mounted () {
        
    },

    
};


</script>

