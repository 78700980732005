
<template>
<div class="mt-5">
    <div class="container-fluid pt-5">
            <main class="form-signin text-center">
                <form>
                    <h1 class="f_22 mb-3 fw-normal">ADMIN PANEL</h1>

                    <div class="form-floating">
                        <input type="email" v-model="email" class="form-control" id="floatingInput" placeholder="Email Address">
                    </div>
                    <div class="form-floating">
                        <input type="password" v-model="password" class="form-control" id="floatingPassword" placeholder="Password">
                    </div>

                    
                    <button @click="login()" class="w-100 btn btn-lg btn-primary" type="button">Sign in</button>
                </form>
            </main>
    </div>

</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child } from "firebase/database";
import MD5 from "crypto-js/md5";
import store from '../store/index'


const config = {
    apiKey: "AIzaSyBPOv9K31Mlx4RPJijM85HIaDxbUjhcGbQ",
    authDomain: "timeout-cd5c8.firebaseapp.com",
    databaseURL: "https://timeout-cd5c8-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "timeout-cd5c8",
    storageBucket: "timeout-cd5c8.appspot.com",
    messagingSenderId: "530353506276",
    appId: "1:530353506276:web:b00ec173ee68026658a9f1"
};

const f_app = initializeApp(config);
//const db = getDatabase(f_app);
//const dbRef = ref(db);



export default {

    components:{
        
    },

    data(){
        return {
            email: "",
            password: ""
        }
    },

    methods:{
        login(){

                const dbRef = ref(getDatabase(f_app));

                get(child(dbRef,"admin")).then((snapshot) => {
                if (snapshot.exists()) {
                    let em = snapshot.val().email;
                    let pss = snapshot.val().password;

                    let md = MD5(this.password).toString();

                    if(em == this.email && pss == md){

                        store.commit('setAdminLogin', true);
                        this.$router.push('/admin901/games');
                    }else{
                        alert("Wrong password or email address. Please try again!");
                    }

                } else {
                    console.log("No data available");
                }
                }).catch((error) => {
                console.error(error);
                });
            
        },
        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },

        
        
    },

    mounted () {
        
        let dd = store.getters.getAdminLogin;
        console.log(dd);
    },

    
};


</script>

