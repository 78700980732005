import Vue from "vue";
import VueRouter from "vue-router";
import Home from './components/Home';
import Admin from './components/Admin';
import AddGame from './components/AddGame';
import Games from './components/Games';
import GameEdit from './components/GameEdit';
import Clues from './components/Clues';
import Master from './components/Master';
import MasterHome from './components/MasterHome';
import MasterGame from './components/MasterGame';
import Rooms from './components/Rooms';
import RoomGame from './components/RoomGame';


Vue.use(VueRouter);

const routes = [

    { name: "Home", component : Home,  path : '/' },
    { name: "Admin", component : Admin,  path : '/admin901' },
    { name: "addGame", component : AddGame,  path : '/admin901/addGame' },
    { name: "Games", component : Games,  path : '/admin901/games' },

    { name: "GameEdit", component : GameEdit,  path : '/admin901/gameEdit/:gameId' },

    { name: "Clues", component : Clues,  path : '/admin901/clues/:gameId' },
    
    { name: "Master", component : Master,  path : '/master' },
    { name: "MasterHome", component : MasterHome,  path : '/master/home' },
    { name: "MasterGame", component : MasterGame,  path : '/master/game/:gameId' },

    { name: "Rooms", component : Rooms,  path : '/rooms' },
    { name: "RoomGame", component : RoomGame,  path : '/room/game/:gameId' },

];

const router = new VueRouter({

    mode: 'history',
    base: '',
    routes
    
});


//const loginRoute = '/login';


    /*
router.beforeEach(async (to, from, next) => {

    const user_group = store.getters.getUserGroup;


    if( to.meta.permission != "all" && user_group != to.meta.permission){
        next({ path: loginRoute });
    }else{
        next();
    }
    

  
    
    
});
  */


export default router;

