
<template>

    <div class="container">
        <div class="row ">
            
            <div class="col-12 text-center f_26 font_semibold mt-4">
                {{gameName}}
            </div>
            <div class="col-12 text-center f_18">
                Duration: {{duration/60}} min, Language: {{lang}} 
            </div>


            <div class="col-8 mt-4">
                <div class="card p-2">
                    <h5 class="card-title  font-weight-bold">HINTS</h5> 
                    <table class="table  mt-2">

                        <th>Puzzle</th>
                        <th>Hint</th>
                        <th class="text-center">Used</th>
                        <th></th>

                        <tr v-for="clue in clues" :key="clue.clue_id">
                            <td>{{clue.clue_puzzle}}</td>
                            <td v-if="lang == 'en'" >{{clue.clue_en}}</td>
                            <td v-if="lang == 'tr'">{{clue.clue_tr}}</td>
                            <td class="text-center">
                                <font-awesome-icon v-if="clue.showed == '1'" icon="check-square" class="" />
                            </td>
                            
                            <td width="200" class="text-right">
                                <button v-if="lastClue != clue.clue_id"  @click="showClue(clue.clue_id)" type="button" class="btn btn-warning">SHOW</button>
                                <button v-if="lastClue == clue.clue_id"  @click="hideClue()" type="button" class="btn btn-dark">HIDE</button>
                                
                            </td>
                        </tr>

                    </table>
                </div>

                <div class="card p-2 mt-3">
                    <h5 class="card-title  font-weight-bold">CUSTOM MESSAGES</h5>

                    <div class="input-group mb-3">
                        <input v-model="writtenMessage" type="text" class="form-control" placeholder="your message" >
                        <div class="input-group-append">
                            <button  @click="sendMessage()" class="btn btn-outline-primary" type="button" >Send</button>
                        </div>
                    </div>


                    <table class="table  mt-2">

                        <th>Message</th>
                        <th></th>
                        
                        <tr v-for="message in messages" :key="message.message_id">
                            <td>{{message.message}}</td>
                            <td width="200" class="text-right"><button @click="sendMessageAgain(message.message_id)" class="btn btn-warning" type="button" >RESHOW</button></td>
                        </tr>

                    </table>
                </div>
            </div>

            <div class="col-4 mt-4">
                <div class="card p-2">
                    <h5 class="card-title text-center font-weight-bold">FINISH GAME</h5>
                    <div class="row ">
                        <div class="col pr-1"><button @click="cancelGame()" type="button" class="btn btn-block btn-sm btn-danger">CANCEL GAME</button></div>
                        <div class="col pl-1"><button @click="successFinish()" type="button" class="btn btn-block btn-sm btn-success">FINISH GAME</button></div>
                    </div>
                    
                </div>

                <div class="card mt-4 p-2 bg-light">
                    <div class="row ">
                        <div class="col-12 text-center f_26 font-weight-bold">
                            {{clock}}
                        </div>
                        <div class="col-12 mt-2 ">
                            <button type="button" @click="showTime()" :class="findShowTimeClass()">Show Time</button>
                        </div>
                    </div>

                    <hr>
                    <div class="f_16 mb-1 text-center font_medium">EDIT TIME</div>

                    <div class="row ">
                        <div class="col pr-1"><button @click="subs5min()" type="button" class="btn btn-block btn-sm btn-outline-secondary font_semibold">-</button></div>
                        <div class="col pr-1 text-center">5 Minutes</div>
                        <div class="col pl-1"><button @click="add5min()" type="button" class="btn btn-block btn-sm btn-outline-secondary font_semibold">+</button></div>
                    </div>
                    
                    <hr>
                    <div class="f_16 mb-1 text-center font_medium">RESET TIME</div>

                    <div class="row ">
                        <div class="col pr-1"><button type="button" @click="resetTime()" class="btn btn-block btn-sm btn-outline-secondary">Reset</button></div>
                    </div>
                    
                </div>

                <div class="card p-2 mt-4">
                    <h5 class="card-title text-center font-weight-bold">SOUNDTRACK</h5>
                    <div class="row ">
                        <div class="col-12 ">
                            <button v-if="soundTrackPlay == '0'" @click="startSoundTrack()" type="button" class="btn btn-block btn-sm btn-success"><font-awesome-icon icon="play" /></button>
                            <button v-if="soundTrackPlay == '1'" @click="stopSoundTrack()" type="button" class="btn btn-block btn-sm btn-danger"><font-awesome-icon icon="stop" /></button>
                        </div>
                    </div>
                </div>


            </div>

            


        </div>

        <div class="powered">Powered by Escapist</div>
    </div>




</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,get,child,ref,set,onValue ,serverTimestamp} from "firebase/database";
import store from '../store/index'


const config = {
    apiKey: "AIzaSyBPOv9K31Mlx4RPJijM85HIaDxbUjhcGbQ",
    authDomain: "timeout-cd5c8.firebaseapp.com",
    databaseURL: "https://timeout-cd5c8-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "timeout-cd5c8",
    storageBucket: "timeout-cd5c8.appspot.com",
    messagingSenderId: "530353506276",
    appId: "1:530353506276:web:b00ec173ee68026658a9f1"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);



export default {

    components:{
        
    },

    data(){
        return {
            lang : "",
            gameId : "",
            gameName : "",
            started_time : 0,
            show_time : "0",
            duration_default : 0,
            duration : 0,
            clock : "00:00:00",
            clues : [],
            messages : [],
            interval : null,
            soundTrackPlay : "0",
            writtenMessage : "",
            lastClue: "0",
        }
    },

    methods:{

        hideClue(){

            set(ref(db, 'games/'+this.gameId+"/lastClue"), "0");

        },

        add5min(){
            if(confirm("Do you really want to add 5 minutes?")){
                console.log(this.duration);
                let min5 = 5*60;
                //let newtime = this.started_time + min5;
                //this.started_time = newtime;
                //set(ref(db, 'games/'+this.gameId+"/started_time"), newtime);

                this.duration = this.duration + min5;
                let minuteDuration = this.duration/60;
                set(ref(db, 'games/'+this.gameId+"/duration"), minuteDuration);

                this.getGameDetails();
            }
        },
        subs5min(){
            if(confirm("Do you really want to subtract 5 minutes?")){
                let min5 = 5*60;

                this.duration = this.duration - min5;
                let minuteDuration = this.duration/60;
                set(ref(db, 'games/'+this.gameId+"/duration"), minuteDuration);

                this.getGameDetails();
            }
        },

        sendMessageAgain(id){

            console.log(id);

            this.messages.forEach(e => {
                if(e.message_id == id){
                    this.writtenMessage = e.message;
                    this.sendMessage();
                }
            });

        },

        sendMessage(){

            let rn = this.randName();
            set(ref(db, 'games/'+this.gameId+"/messages/"+rn), {
                message: this.writtenMessage
            });

            set(ref(db, 'games/'+this.gameId+"/lastMessage"), rn);

            this.writtenMessage = "";
            this.getMessages();

        },

        resetTime(){
            if(confirm("Do you really want to reset the time?")){
                set(ref(db, 'games/'+this.gameId+"/started_time"), serverTimestamp());
                set(ref(db, 'games/'+this.gameId+"/duration"), this.duration_default);
                this.getGameDetails();
                this.getClues();
            }
        },

        cancelGame(){

            if(confirm("Do you really want to cancel the game?")){
                clearInterval(this.interval);
                this.interval = null;
                set(ref(db, 'games/'+this.gameId+"/messages"), null);
                set(ref(db, 'games/'+this.gameId+"/lastClue"), "0");
                set(ref(db, 'games/'+this.gameId+"/lastMessage"), "0");
                set(ref(db, 'games/'+this.gameId+"/show_time"), "0");
                set(ref(db, 'games/'+this.gameId+"/status"), "waiting");
                set(ref(db, 'games/'+this.gameId+"/started"), "0");
                set(ref(db, 'games/'+this.gameId+"/finished_time"), 0);
                set(ref(db, 'games/'+this.gameId+"/started_time"), 0);
                set(ref(db, 'games/'+this.gameId+"/duration"), this.duration_default);

                this.clues.forEach(e => {
                    set(ref(db, 'games/'+this.gameId+"/clues/"+e.clue_id+"/showed"), "0");
                });

                this.$router.push('/master/home');
            }

        },

        startSoundTrack(){
            this.soundTrackPlay = "1";
            set(ref(db, 'games/'+this.gameId+"/soundTrackPlay"), "1");
        },
        stopSoundTrack(){
            this.soundTrackPlay = "0";
            set(ref(db, 'games/'+this.gameId+"/soundTrackPlay"), "0");
        },

        findShowTimeClass(){
            let cls = "btn btn-block btn-sm btn-outline-success";
            if(this.show_time == "1"){
                cls = "btn btn-block btn-sm btn-success";
            }
            return cls
        },

        showClue(id){
            if(confirm("Do you really want to show this hint?")){
            
                set(ref(db, 'games/'+this.gameId+"/lastClue"), id);
                set(ref(db, 'games/'+this.gameId+"/clues/"+id+"/showed"), "1");

                this.clues.forEach(element => {
                    if(element.clue_id == id){
                        element.showed = "1"
                    }
                });

            }
        },

        successFinish(){

            if(confirm("Do you really want to finish the game?")){
                clearInterval(this.interval);
                this.interval = null;
                set(ref(db, 'games/'+this.gameId+"/messages"), null);
                set(ref(db, 'games/'+this.gameId+"/lastClue"), "0");
                set(ref(db, 'games/'+this.gameId+"/show_time"), "0");
                set(ref(db, 'games/'+this.gameId+"/lastMessage"), "0");
                set(ref(db, 'games/'+this.gameId+"/status"), "finished");
                set(ref(db, 'games/'+this.gameId+"/started"), "2");
                set(ref(db, 'games/'+this.gameId+"/finished_time"), serverTimestamp());
                set(ref(db, 'games/'+this.gameId+"/duration"), this.duration_default);

                this.clues.forEach(e => {
                    set(ref(db, 'games/'+this.gameId+"/clues/"+e.clue_id+"/showed"), "0");
                });

                this.$router.push('/master/home');
            }

            
        },

        showTime(){
            if(this.show_time == "0"){
                set(ref(db, 'games/'+this.gameId+"/show_time"), "1");
                this.show_time = "1";
            }else{
                set(ref(db, 'games/'+this.gameId+"/show_time"), "0");
                this.show_time = "0";
            }
            
        },

        startGame(id){
            this.$router.push('/master/game/'+id);
        },

        padLeadingZeros(num, size) {
            var s = num+"";
            while (s.length < size) s = "0" + s;
            return s;
        },

        countdown(){
            let now = Date.now();
            let dif = Math.round((now - this.started_time) / 1000);
            let remaining =  this.duration - dif;
            //console.log(remaining);

            if(remaining <= 0){
                remaining = 0;
                this.clock = "00:00:00";
            }else{
                let hour = Math.floor(remaining / 3600);
                let minute = Math.floor( (remaining - hour*3600) / 60 );
                let second = remaining - (hour*3600) - (minute*60);
                this.clock = this.padLeadingZeros(hour,2) + ":" + this.padLeadingZeros(minute,2) + ":" + this.padLeadingZeros(second,2);
            }

            

        },

        getGameDetails(){

                const refLastOpen = ref(db, "games/"+this.gameId);
                onValue(refLastOpen, (snapshot) => {
                    if (snapshot.exists()) {

                        
                        this.soundTrackPlay = snapshot.val().soundTrackPlay;
                        this.show_time = snapshot.val().show_time;
                        this.started_time = snapshot.val().started_time;
                        this.lang = snapshot.val().lang;
                        this.lastClue = snapshot.val().lastClue;
                        this.duration = snapshot.val().duration * 60;
                        this.duration_default = snapshot.val().duration_default;
                        if(snapshot.val().lang == "en"){
                            this.gameName = snapshot.val().gameName_en;
                        }else{
                            this.gameName = snapshot.val().gameName_tr;
                        }

                        this.$store.commit('setGameId', snapshot.val().gameId);
                        this.$store.commit('setStarted_time', snapshot.val().started_time);
                        this.$store.commit('setLanguage', snapshot.val().lang);

                        console.log(this.clues);

                    } else {
                        console.log("No data available");
                    }
                });

        },
            
        getClues(){
            
            this.clues = [];
            let nRefusers = "/games/"+this.gameId+"/clues";
            get(child(dbRef, nRefusers)).then((snapshot) => {
            if (snapshot.exists()) {


                snapshot.forEach(e => {

                    let temp = {
                        clue_id:e.key,
                        clue_en: e.val().clue_en,
                        clue_tr:e.val().clue_tr,
                        clue_puzzle:e.val().clue_puzzle,
                        showed:e.val().showed
                    };
                    this.clues.push(temp);
                }); 



            } 
            }).catch((error) => {
                console.log(error);
            });

            console.log(this.clues);

        },

        getMessages(){
            
            this.messages = [];
            let nRefusers = "/games/"+this.gameId+"/messages";
            get(child(dbRef, nRefusers)).then((snapshot) => {
            if (snapshot.exists()) {


                snapshot.forEach(e => {

                    let temp = {
                        message_id:e.key,
                        message: e.val().message
                    };
                    this.messages.push(temp);
                }); 

            } 
            }).catch((error) => {
                console.log(error);
            });

            console.log(this.clues);

        },
        
        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },


        
    },

    created() {
        this.gameId = this.$route.params.gameId;
        this.getGameDetails();
        this.getClues();
        this.getMessages();
    },

    mounted: function () {
        this.interval = setInterval(() => {
            this.countdown();
        }, 1000)

        if(!store.getters.getMasterLogin){
            this.$router.push('/master');
        }
    },
    watch: {

        

        lastClue : function(val, oldVal){
            if(val !== oldVal){

                this.lastClue = val;
                
            }
        },

        

    },
    
};


</script>

