
<template>

    <div class="bgGame" v-bind:style="{ backgroundImage: 'url(https://timeoutcluesystem.com/api/' + gameImage + ')' }" >

        <div v-if="show_timeline == '1'" :class="tl_colorCss()">
            <div :class="tl2_colorCss()" v-bind:style="{ width: timelineW }"></div>
        </div>

        <div v-if="show_time == '1'" class="timeDiv font-weight-bold">
            {{clock}}
        </div>

        <div :class="finishDivCss()">
            <div class="text-center mt-5"><img src="@/assets/taktic.png" width="450px"></div>

            <div class="finishDiv_ic mt-4">
                <div v-if="lang == 'tr'" class="f_56 text-center font_semibold">TEBRİKLER</div>
                <div v-if="lang == 'en'" class="f_56 text-center font_semibold">CONGRATULATIONS</div>

                <div v-if="lang == 'tr'" class="mt-4 text-center font_semibold color_tur f_46">SÜRE</div>
                <div v-if="lang == 'en'" class="mt-4 text-center font_semibold color_tur f_46">YOUR TIME</div>

                <div class="text-center f_76 font_semibold finishDiv_timeborder mt-2 mb-5"  v-bind:style="{ width: '500px' }"> {{clockFinished}}</div>

                <div class="mt-5 f_46 text-center">{{gameName}}</div>
            </div>
            <div class="mt-5 f_36 color_white text-center">timeoutcluesystem.com</div>
        </div>

        <div  :class="clueDivCss()" >
            <div class="mt-4 color_white f_36 font_semibold clueDivTitleBorder"><img src="@/assets/lamp.png" width="80px"> 
            <span class="clueDivTitle">{{gameName}}</span>
            </div>

            <div class="color_white f_36 mt-3 clueDivTextH">{{clueText}}</div>

            <div class="clueDivTitleBorderTop">
                <div class="row">
                    <div class="col"><img src="@/assets/taktic.png" width="120px"> <span class="clueDivFooterText color_white f_26">timeoutcluesystem.com</span> </div>
                    <div class="col text-right color_tur f_30 font_semibold">
                        {{clock}}
                    </div>
                </div>
                
                
            </div>
        </div>

        <div :class="messageDivCss()" >
            <div class="mt-4 color_white f_36 font_semibold clueDivTitleBorder"><img src="@/assets/lamp.png" width="80px"> 
            <span class="clueDivTitle">{{gameName}}</span>
            </div>

            <div class="color_white f_36 mt-3 clueDivTextH">{{clueText}}</div>

            <div class="clueDivTitleBorderTop">
                <div class="row">
                    <div class="col"><img src="@/assets/taktic.png" width="120px"> <span class="clueDivFooterText color_white f_26">timeoutcluesystem.com</span> </div>
                    <div class="col text-right color_tur f_30 font_semibold">
                        {{clock}}
                    </div>
                </div>
                
                
            </div>
        </div>



    </div>

</template>


<style lang="scss">
@import '~@/styles/home.css';

.bgGame{
    background-color: #000;
    background-size: cover;
    height: 100vh;
}
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,onValue,ref,set,get,child } from "firebase/database";


const config = {
    apiKey: "AIzaSyBPOv9K31Mlx4RPJijM85HIaDxbUjhcGbQ",
    authDomain: "timeout-cd5c8.firebaseapp.com",
    databaseURL: "https://timeout-cd5c8-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "timeout-cd5c8",
    storageBucket: "timeout-cd5c8.appspot.com",
    messagingSenderId: "530353506276",
    appId: "1:530353506276:web:b00ec173ee68026658a9f1"
};


const f_app = initializeApp(config);
const db = getDatabase(f_app);
//const dbRef = ref(db);



export default {

    components:{
        
    },

    data(){
        return {
            lang : "",
            gameId : "",
            gameName : "",
            gameImage : "",
            status : "started",
            started_time : 0,
            finished_time : 0,
            duration : 0,
            clock : "00:00:00",
            clockFinished : "00:00:00",
            clues : [],
            interval : null,
            timelineW: "100%",
            show_time: "0",
            show_timeline: "0",
            show_finish: "0",
            show_clue: "0",
            show_message: "0",
            lastClue: "0",
            lastMessage: "0",
            clueText : "",

            soundTrackUrl : "",
            soundClueUrl : "",

            audioTrack : null,
            audioClue : null,

            url_track : "",
            url_clue : "",

            soundTrackPlay : "0",

            tl_color : "0"
        }
    },

    methods:{

        test(){
            console.log(this.status);
            console.log(this.show_timeline);
            //this.show_finish = "0";
        },

        hidelastClue(){


            this.show_clue = "0";
            this.show_timeline = "1";
            set(ref(db, 'games/'+this.gameId+"/lastClue"), "0");
            this.$store.commit('setLastClueId',"0");


        },

        showLastClue(){

            const dbRef = ref(getDatabase());
            get(child(dbRef,"games/"+this.gameId+"/clues/"+this.lastClue)).then((snapshot) => {
            if (snapshot.exists()) {

                if(this.lang == "en"){
                    this.clueText = snapshot.val().clue_en;
                }else{
                    this.clueText = snapshot.val().clue_tr;
                }
                
                this.show_timeline = "0";
                this.show_time = "0";
                set(ref(db, 'games/'+this.gameId+"/show_time"), "0");
                this.show_clue = "1";
                this.$forceUpdate();

                this.audioClue.play();

                this.$store.commit('setLastClueId', this.lastClue);


                
                setTimeout(() => {
                    this.hidelastClue()
                }, 240000);




            } else {
                console.log("No data available");
            }
            }).catch((error) => {
            console.error(error);
            });
            

        },

        hideLastMessage(){
            this.show_message = "0";
            this.show_timeline = "1";
            set(ref(db, 'games/'+this.gameId+"/lastMessage"), "0");
            this.$store.commit('setLastMessageId',"0");

        },

        showLastMessage(){
            const dbRef = ref(getDatabase());
            get(child(dbRef,"games/"+this.gameId+"/messages/"+this.lastMessage)).then((snapshot) => {
            if (snapshot.exists()) {

                this.clueText = snapshot.val().message;

                
                this.show_timeline = "0";
                this.show_time = "0";
                set(ref(db, 'games/'+this.gameId+"/show_time"), "0");
                this.show_clue = "0";
                this.show_message = "1";
                this.$forceUpdate();

                this.audioClue.play();

                setTimeout(() => {
                    this.hideLastMessage();
                }, 240000);


                this.$store.commit('setLastMessageId', this.lastMessage);




            } else {
                console.log("No data available");
            }
            }).catch((error) => {
            console.error(error);
            });
        },

        finishDivCss(){
            let cls = "finishDiv";
            if(this.show_finish == "1"){
                cls = "finishDiv finishDiv_move";
            }
            return cls
        },
        clueDivCss(){
            let cls = "clueDiv";
            if(this.show_clue == "1"){
                cls = "clueDiv clueDiv_move";
            }
            return cls
        },
        messageDivCss(){
            let cls = "clueDiv";
            if(this.show_message == "1"){
                cls = "clueDiv clueDiv_move";
            }
            return cls
        },

        tl_colorCss(){
            let cls = "timeline_border";
            if(this.tl_color == "1"){
                cls = "timeline_border_red";
            }
            return cls
        },
        tl2_colorCss(){
            let cls = "timeline";
            if(this.tl_color == "1"){
                cls = "timeline_red";
            }
            return cls
        },

        startGame(id){
            this.$router.push('/master/game/'+id);
        },

        padLeadingZeros(num, size) {
            var s = num+"";
            while (s.length < size) s = "0" + s;
            return s;
        },

        countdown(){
            let now = Date.now();
            let dif = Math.round((now - this.started_time) / 1000);
            let remaining =  this.duration - dif;
            //console.log(remaining);

            if(remaining <= 900){
                this.tl_color = "1";
            }else{
                this.tl_color = "0";
            }

            if(remaining <= 0){
                remaining = 0;
                this.clock = "00:00:00";
                this.timelineW = "0%";
            }else{

                let hour = Math.floor(remaining / 3600);
                let minute = Math.floor( (remaining - hour*3600) / 60 );
                let second = remaining - (hour*3600) - (minute*60);
                this.clock = this.padLeadingZeros(hour,2) + ":" + this.padLeadingZeros(minute,2) + ":" + this.padLeadingZeros(second,2);

                let tm_w = remaining * 100 / this.duration
                this.timelineW = tm_w + "%";
            }

            
            
        },

        setFinishTime(){

            let now = this.finished_time;
            let dif = Math.round((now - this.started_time) / 1000);
            let remaining =  this.duration - dif;
            //console.log(remaining);

            let hour = Math.floor(remaining / 3600);
            let minute = Math.floor( (remaining - hour*3600) / 60 );
            let second = remaining - (hour*3600) - (minute*60);

            this.clockFinished = this.padLeadingZeros(hour,2) + ":" + this.padLeadingZeros(minute,2) + ":" + this.padLeadingZeros(second,2);


        },

        getGameDetails(){

                const refLastOpen = ref(db, "games/"+this.gameId);
                onValue(refLastOpen, (snapshot) => {
                    if (snapshot.exists()) {

                        this.finished_time = snapshot.val().finished_time;
                        this.show_time = snapshot.val().show_time;
                        this.status = snapshot.val().status;
                        this.gameImage = snapshot.val().gameImage;
                        this.started_time = snapshot.val().started_time;
                        this.lang = snapshot.val().lang;
                        this.duration = snapshot.val().duration * 60;
                        this.lastClue = snapshot.val().lastClue;
                        this.soundTrackPlay = snapshot.val().soundTrackPlay;
                        this.lastMessage = snapshot.val().lastMessage;


                        this.soundTrackUrl = snapshot.val().soundTrack;
                        this.soundClueUrl = snapshot.val().soundClue;

                        this.url_track = 'https://timeoutcluesystem.com/api/' + this.soundTrackUrl;
                        
                        
                        this.url_clue = 'https://timeoutcluesystem.com/api/' + this.soundClueUrl;
                        this.audioClue = new Audio(this.url_clue);
                        

                        if(snapshot.val().lang == "en"){
                            this.gameName = snapshot.val().gameName_en;
                        }else{
                            this.gameName = snapshot.val().gameName_tr;
                        }

                        
                        this.$store.commit('setGameId', snapshot.val().gameId);
                        this.$store.commit('setStarted_time', snapshot.val().started_time);
                        this.$store.commit('setLanguage', snapshot.val().lang);

                        this.sceneSetup()


                    } else {
                        console.log("No data available");
                    }
                });



        },



        myClickEvent_t() {
            console.log("myClickEvent");
        },
            

        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },

        sceneSetup(){
            
            if (this.status == "waiting") {
                clearInterval(this.interval);
                this.interval = null;
                this.show_timeline = "0";
            }

            if (this.status == "started" ) {

                if(this.show_clue == "0"){
                    this.show_timeline = "1";
                }
                

                this.interval = setInterval(() => {
                    this.countdown();
                }, 1000);

            }


            if (this.status == "finished") {

                this.show_timeline = "0";
                this.show_time = "0";
                set(ref(db, 'games/'+this.gameId+"/show_time"), "0");
                this.show_clue = "0";
                
                clearInterval(this.interval);
                this.interval = null;

                this.setFinishTime();
                this.show_finish = "1";

                setTimeout(() => {
                    this.status = "waiting";
                    this.show_time = "0";
                    this.show_timeline = "0";
                    this.show_finish = "0";
                    set(ref(db, 'games/'+this.gameId+"/status"), "waiting");
                    set(ref(db, 'games/'+this.gameId+"/started"), "0");
                    set(ref(db, 'games/'+this.gameId+"/finished_time"), 0);
                    set(ref(db, 'games/'+this.gameId+"/started_time"), 0);
                    set(ref(db, 'games/'+this.gameId+"/lastClue"), "0");

                    this.$store.commit('setLastClueId', "");
                    
                }, 10000);


            }
        },

        startSoundtrack(){
            this.audioTrack = new Audio(this.url_track);
            this.audioTrack.autoplay = true;
            this.audioTrack.loop = true;
            this.audioTrack.play();
        },

        stopSoundtrack(){
            console.log("stop");
            this.audioTrack.pause();
            this.audioTrack = null;
        },


        
    },

    created() {
        this.gameId = this.$route.params.gameId;
        this.getGameDetails();
    },

    mounted: function () {
        if (this.status == "started") {

            this.interval = setInterval(() => {
                this.countdown();
            }, 1000);

        }
        
    },

    watch: {

        status : function(val, oldVal){

            if (val == "waiting") {
                console.log("waiting");
            }


            if (val == "started" ) {

                this.interval = setInterval(() => {
                    this.countdown();
                }, 1000);

            }

            if (val == "finished") {
                console.log("finished");
            }

            this.sceneSetup();

            console.log(oldVal);
        },

        lastClue : function(val, oldVal){
            if(val !== oldVal){

                let LastClueId = this.$store.getters.getLastClueId;

                if(LastClueId != val){
                    

                    if(val == "0"){
                        this.hidelastClue();
                    }else{
                        this.show_timeline = "0";
                        this.show_time = "0";
                        set(ref(db, 'games/'+this.gameId+"/show_time"), "0");
                        this.showLastClue();
                    }
                }
                
                
            }
        },

        lastMessage : function(val, oldVal){
            if(val !== oldVal){

                console.log(val);

                let LastMessageId = this.$store.getters.getLastMessageId;

                if(LastMessageId != val){

                    if(val == "0"){
                        this.hideLastMessage();
                    }else{
                        this.show_timeline = "0";
                        this.show_time = "0";
                        set(ref(db, 'games/'+this.gameId+"/show_time"), "0");
                        this.showLastMessage();
                    }

                }
                
                
            }
        },

        soundTrackPlay : function(val, oldVal){

            if(val == "1" && oldVal =="0"){
                this.startSoundtrack();
                
            }
            if(val == "0"){
                this.stopSoundtrack();
            }
        },
        

    },
    
};


</script>

