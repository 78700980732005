
<template>

    <div class="container">
        <div class="row ">
            
            <div class="col-12 text-center f_26 font_semibold mt-4">
                PLEASE CHOOSE A GAME TO CONTINUE
            </div>

            <div v-for="game in games" :key="game.gameId" class="col-6 mt-4 ">
                <div class="card p-4">
                    <div class="row">
                        <div class="col-5"><img :src="'https://timeoutcluesystem.com/api/'+game.gameImage" width="100%"></div>
                        <div class="col-7">
                            <div class="f_18 font_medium">{{game.gameName_en}}</div>
                            <div class="f_18 font_medium mt-2">{{game.gameName_tr}}</div>
                            <hr>
                            <div>Duration: {{game.duration}} minutes</div>
                            <hr>
                            <select  v-model="langSelect" class="form-control" id="exampleFormControlSelect1">
                                <option value="en">English</option>
                                <option value="tr">Turkish</option>
                            </select>

                            <button v-if="game.status != 'started'" type="button" @click="startGame(game.gameId)" class="btn btn-success mt-2 btn-block">Start the Game</button>
                            <button v-if="game.status == 'started'" type="button" @click="continueGame(game.gameId)" class="btn btn-warning mt-2 btn-block">Continue Game</button>

                        </div>
                    </div>
                    
                </div>
            </div>


        </div>
        <div class="powered">Powered by Escapist</div>
    </div>




</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,onValue,ref,set,serverTimestamp } from "firebase/database";
import store from '../store/index'


const config = {
    apiKey: "AIzaSyBPOv9K31Mlx4RPJijM85HIaDxbUjhcGbQ",
    authDomain: "timeout-cd5c8.firebaseapp.com",
    databaseURL: "https://timeout-cd5c8-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "timeout-cd5c8",
    storageBucket: "timeout-cd5c8.appspot.com",
    messagingSenderId: "530353506276",
    appId: "1:530353506276:web:b00ec173ee68026658a9f1"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
//const dbRef = ref(db);



export default {

    components:{
        
    },

    data(){
        return {
            games : [],
            langSelect : "en"
        }
    },

    methods:{

        startGame(id){

            console.log(this.langSelect);
            set(ref(db, 'games/'+id+"/status"), "started");
            set(ref(db, 'games/'+id+"/started"), "1");
            set(ref(db, 'games/'+id+"/lang"), this.langSelect);
            set(ref(db, 'games/'+id+"/started_time"), serverTimestamp());
            this.$router.push('/master/game/'+id);
        },
        continueGame(id){
            this.$router.push('/master/game/'+id);
        },
            
        getGames(){

            
            const refLastOpen = ref(db, "games");
            onValue(refLastOpen, (snapshot) => {
                if (snapshot.exists()) {

                    this.games = [];

                    snapshot.forEach(e => {
                        console.log(e.val());
                        this.games.push(e.val());

                    }); 

                } 
            });

            console.log(this.games);

        },
        
        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },


        
    },

    mounted () {
        this.getGames();

        if(!store.getters.getMasterLogin){
            this.$router.push('/master');
        }
    },

    
};


</script>

