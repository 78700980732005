
<template>
<div class="">
<div class="container-fluid">
    <div class="row flex-nowrap">
        <LeftMenu />
        <div class="col py-3">
            
            <div class="f_22 font_semibold">EDIT GAME</div>

            <div class="card mt-3">
                <div class="card-body">
                    
                    <div class="row mt-3">
                        <div class="col-3">Game Name - English</div>
                        <div class="col-9">
                            <input v-model="gameNameEnglish" class="form-control">
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-3">Game Name - Turkish</div>
                        <div class="col-9">
                            <input v-model="gameNameTurkish" class="form-control">
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-3">Game Duration - Minutes</div>
                        <div class="col-9">
                            <input v-model="gameDuration" class="form-control">
                        </div>
                    </div>

                    <div v-if="saveAlert == 1" class="alert alert-success mt-3" role="alert">
                        Saved successfully!
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 text-right">
                            <button @click="saveGame()" type="button" class="btn btn-primary">SAVE</button>
                        </div>
                        
                    </div>


                </div>
            </div>

            <div class="card mt-3">
                <div class="card-body">

                    <div class="row mt-3">
                        <div class="col-3">Game Image</div>
                        <div class="col-2"><img :src="'https://timeoutcluesystem.com/api/'+gameImageUrl" width="100%"></div>
                        <div class="col-7">
                            <input type="file"  @change="previewFiles" class="form-control-file" id="exampleFormControlFile1">
                        </div>
                    </div>

                    <div v-if="saveImageAlert == 1" class="alert alert-success mt-3" role="alert">
                        Saved successfully!
                    </div>


                    <div class="row mt-3">
                        <div class="col-12 text-right">
                            <button @click="saveImage()" type="button" class="btn btn-primary">SAVE IMAGE</button>
                        </div>
                    </div>

                </div>    
            </div>


            <div class="card mt-3">
                <div class="card-body">

                    <div class="row mt-3">
                        <div class="col-3">Sound Track</div>
                        <div class="col-9">
                            
                            <button v-if="gameTrackUrl != ''" @click="runTrack()"><font-awesome-icon icon="play" class=" mr-1" /></button>
                            <button v-if="gameTrackUrl != ''" @click="stopTrack()"><font-awesome-icon icon="pause" class=" mr-1" /></button>

                            <input type="file"  @change="previewFilesSoundTrack" class="form-control-file mt-4" id="exampleFormControlFile1">
                        </div>
                    </div>

                    <div v-if="saveTrackAlert == 1" class="alert alert-success mt-3" role="alert">
                        Saved successfully!
                    </div>


                    <div class="row mt-3">
                        <div class="col-12 text-right">
                            <button @click="saveTrack()" type="button" class="btn btn-primary">SAVE SOUND</button>
                        </div>
                    </div>

                </div>    
            </div>



            <div class="card mt-3">
                <div class="card-body">

                    <div class="row mt-3">
                        <div class="col-3">Clue Sound</div>
                        <div class="col-9">
                            
                            <button v-if="gameClueUrl != ''" @click="runClue()"><font-awesome-icon icon="play" class=" mr-1" /></button>
                            <button v-if="gameClueUrl != ''" @click="stopClue()"><font-awesome-icon icon="pause" class=" mr-1" /></button>

                            <input type="file"  @change="previewFilesClueSound" class="form-control-file mt-4" id="exampleFormControlFile1">
                        </div>
                    </div>

                    <div v-if="saveClueAlert == 1" class="alert alert-success mt-3" role="alert">
                        Saved successfully!
                    </div>


                    <div class="row mt-3">
                        <div class="col-12 text-right">
                            <button @click="saveClue()" type="button" class="btn btn-primary">SAVE SOUND</button>
                        </div>
                    </div>

                </div>    
            </div>

        </div>
    </div>
</div>








</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,ref,set,get,child } from "firebase/database";
import LeftMenu from '../components/LeftMenu.vue'
import axios from 'axios';
import store from '../store/index'


const config = {
    apiKey: "AIzaSyBPOv9K31Mlx4RPJijM85HIaDxbUjhcGbQ",
    authDomain: "timeout-cd5c8.firebaseapp.com",
    databaseURL: "https://timeout-cd5c8-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "timeout-cd5c8",
    storageBucket: "timeout-cd5c8.appspot.com",
    messagingSenderId: "530353506276",
    appId: "1:530353506276:web:b00ec173ee68026658a9f1"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
//const dbRef = ref(db);



export default {

    components:{
        LeftMenu
    },

    data(){
        return {
            gameId : "",
            gameNameEnglish : "",
            gameNameTurkish : "",
            gameDuration: 0,
            gameImageUrl : "",
            gameTrackUrl : "",
            gameClueUrl : "",
            gameImage : null,
            soundTrack : null,
            soundClue : null,

            saveAlert :0,
            saveImageAlert :0,
            saveTrackAlert :0,
            saveClueAlert :0,

            audioTrack : null,
            audioClue : null,

        }
    },

    methods:{
        previewFiles(event) {
            this.gameImage = event.target.files[0];
        },
        previewFilesSoundTrack(event) {
            this.soundTrack = event.target.files[0];
        },
        previewFilesClueSound(event) {
            this.soundClue = event.target.files[0];
        },

        runTrack(){
            let url = 'https://timeoutcluesystem.com/api/' + this.gameTrackUrl;
            this.audioTrack = new Audio(url);
            this.audioTrack.loop = true;
            this.audioTrack.play();
        },
        stopTrack(){
            this.audioTrack.pause();
            this.audioTrack = null;
        },
        runClue(){
            let url = 'https://timeoutcluesystem.com/api/' + this.gameClueUrl;
            this.audioClue = new Audio(url);
            this.audioClue.play();
        },
        stopClue(){
            this.audioClue.pause();
            this.audioClue = null;
        },


        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },

        getGameDetails(){

                const dbRef = ref(getDatabase());
                get(child(dbRef,"games/"+this.gameId)).then((snapshot) => {
                if (snapshot.exists()) {
                    console.log(snapshot.val());
                    this.gameNameEnglish = snapshot.val().gameName_en;
                    this.gameNameTurkish = snapshot.val().gameName_tr;
                    this.gameDuration = snapshot.val().duration;
                    this.gameImageUrl = snapshot.val().gameImage;
                    this.gameTrackUrl = snapshot.val().soundTrack;
                    this.gameClueUrl = snapshot.val().soundClue;
                } else {
                    console.log("No data available");
                }
                }).catch((error) => {
                console.error(error);
                });

        },

        saveGame(){


            set(ref(db, 'games/'+this.gameId+"/gameName_en"), this.gameNameEnglish);
            set(ref(db, 'games/'+this.gameId+"/gameName_tr"), this.gameNameTurkish);
            set(ref(db, 'games/'+this.gameId+"/duration"), this.gameDuration);
        
            this.saveAlert = 1;
            setTimeout(() => {
                this.saveAlert = 0;
            }, 2000);

        },

        saveImage(){
            const fd = new FormData();

            fd.append('image', this.gameImage);

            axios.post("https://timeoutcluesystem.com/api/addImage",fd).then(
                response => {

                    set(ref(db, 'games/'+this.gameId+"/gameImage"), response.data.imageName);

                    this.gameImageUrl = response.data.imageName;

                    this.saveImageAlert = 1;
                    setTimeout(() => {
                        this.saveImageAlert = 0;
                    }, 2000);

                }  

            );
        },
        
        saveTrack(){

            
            const fd = new FormData();

            fd.append('track', this.soundTrack);

            axios.post("https://timeoutcluesystem.com/api/addTrack",fd).then(
                response => {

                    console.log(response.data);

                    set(ref(db, 'games/'+this.gameId+"/soundTrack"), response.data.trackName);

                    this.gameTrackUrl = response.data.trackName;

                    this.saveTrackAlert = 1;
                    setTimeout(() => {
                        this.saveTrackAlert = 0;
                    }, 2000);

                }  

            );
        },

        saveClue(){
            const fd = new FormData();

            fd.append('track', this.soundClue);

            axios.post("https://timeoutcluesystem.com/api/addClue",fd).then(
                response => {

                    console.log(response.data);

                    set(ref(db, 'games/'+this.gameId+"/soundClue"), response.data.trackName);

                    this.gameClueUrl = response.data.trackName;

                    this.saveClueAlert = 1;
                    setTimeout(() => {
                        this.saveClueAlert = 0;
                    }, 2000);

                }  

            );
        }
        
    },

    created() {
        this.gameId = this.$route.params.gameId;
        this.getGameDetails();
    },

    mounted () {
        if(!store.getters.getAdminLogin){
            this.$router.push('/admin901');
        }
    },

    
};


</script>

