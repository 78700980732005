
<template>
<div class="">
<div class="container-fluid">
    <div class="row flex-nowrap">
        <LeftMenu />
        <div class="col py-3">
            <div class="f_22 font_semibold">GAMES</div>

            <table class="table table-bordered mt-3">

                <th>Name - English</th>
                <th>Name - Turkish</th>
                <th>Duration</th>
                <th>Clues</th>
                <th></th>

                <tr v-for="game in games" :key="game.gameId">
                    <td>{{game.gameName_en}}</td>
                    <td>{{game.gameName_tr}}</td>
                    <td>{{game.duration}} min</td>
                    <td>
                        <a :href="'/admin901/clues/'+game.gameId" type="button" class="btn btn-danger mr-2">{{game.clues == null ? '0' : game.clues }}</a>
                    </td>
                    <td width="200" class="text-right">
                        <button @click="deleteGame(game.gameId)" type="button" class="btn btn-danger mr-2">Delete</button>
                        <button @click="editGame(game.gameId)" type="button" class="btn btn-warning">Edit</button>
                    </td>
                </tr>

            </table>

        </div>
    </div>
</div>








</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set } from "firebase/database";
import LeftMenu from '../components/LeftMenu.vue'
import store from '../store/index'


const config = {
    apiKey: "AIzaSyBPOv9K31Mlx4RPJijM85HIaDxbUjhcGbQ",
    authDomain: "timeout-cd5c8.firebaseapp.com",
    databaseURL: "https://timeout-cd5c8-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "timeout-cd5c8",
    storageBucket: "timeout-cd5c8.appspot.com",
    messagingSenderId: "530353506276",
    appId: "1:530353506276:web:b00ec173ee68026658a9f1"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);



export default {

    components:{
        LeftMenu
    },

    


    data(){
        return {
            games : []
        }
    },

    methods:{
        editGame(id){
            this.$router.push('/admin901/gameEdit/'+id);
        },
        deleteGame (id){
            if(confirm("Are you sure you want to delete?")){
                console.log(id);
                set(ref(db, 'games/'+id), null );
                this.getGames();
            }
        },
        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },

        getGames(){
            
            this.games = [];
            let nRefusers = "/games";
            get(child(dbRef, nRefusers)).then((snapshot) => {
            if (snapshot.exists()) {

                snapshot.forEach(e => {

                    if(e.val().clues != null){

                        get(child(dbRef, "/games/"+e.val().gameId+"/clues")).then((snp) => {

                            let i =0;
                            snp.forEach(() => {
                                i++;
                            });

                            let temp = {
                                gameId:e.val().gameId,
                                duration: e.val().duration,
                                gameImage: e.val().gameImage,
                                gameName_en:e.val().gameName_en,
                                gameName_tr:e.val().gameName_tr,
                                clues : i
                            };

                            this.games.push(temp);
                        
                        });

                    }else{

                        let temp = {
                            gameId:e.val().gameId,
                            duration: e.val().duration,
                            gameImage: e.val().gameImage,
                            gameName_en:e.val().gameName_en,
                            gameName_tr:e.val().gameName_tr,
                            clues : 0
                        };

                        this.games.push(temp);

                    }
                    

                    

                    
                }); 




            } 
            }).catch((error) => {
                console.log(error);
            }).then(()=> {
                //this.users = this.orderedItems(this.users);
            }).then(()=>{
                //this.users = this.users.splice(0, 10);
            });

            console.log(this.games);

        },
        
    },

    mounted () {
        this.getGames();
        if(!store.getters.getAdminLogin){
            this.$router.push('/admin901');
        }
    },

    
};


</script>

