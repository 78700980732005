import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'

//import Auth from './modules/auth'

Vue.use(Vuex);

const getters = {

    getLanguage: state => {
        return state.language
    },
    getGameId: state => {
        return state.gameId
    },
    getStarted_time: state => {
        return state.started_time
    },
    getLastClueId: state => {
        return state.lastClueId
    },
    getLastMessageId: state => {
        return state.lastMessageId
    },

    
    getAdminLogin: state => {
        return state.adminLogin
    },
    getMasterLogin: state => {
        return state.masterLogin
    },
    
};
const actions = {};
const mutations = {
    
    setAdminLogin (state, data) {
        state.adminLogin = data;
    },
    setMasterLogin (state, data) {
        state.masterLogin = data;
    },
    setLanguage (state, data) {
        state.language = data;
    },
    setGameId (state, data) {
        state.gameId = data;
    },
    setStarted_time (state, data) {
        state.started_time = data;
    },
    setLastClueId (state, data) {
        state.lastClueId = data;
    },
    setLastMessageId (state, data) {
        state.lastMessageId = data;
    },


};

export const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        adminLogin : false,
        masterLogin : false,
        language : "",
        gameId : "",
        started_time : 0,
        lastClueId : "",
        lastMessageId : ""


    },
    actions,
    getters,
    mutations,
});

export default store;

