
<template>
<div class="container  pb-5">
    <div class="text-center">


        


    </div>
</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,ref,set } from "firebase/database";

const config = {
    apiKey: "AIzaSyBPOv9K31Mlx4RPJijM85HIaDxbUjhcGbQ",
    authDomain: "timeout-cd5c8.firebaseapp.com",
    databaseURL: "https://timeout-cd5c8-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "timeout-cd5c8",
    storageBucket: "timeout-cd5c8.appspot.com",
    messagingSenderId: "530353506276",
    appId: "1:530353506276:web:b00ec173ee68026658a9f1"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
//const dbRef = ref(db);



export default {

    components:{
        //HeaderBar
    },

    data(){
        return {
        
        }
    },

    methods:{
        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },

        addGame(){
            

            set(ref(db, this.randName()), {
                adminPassword: "dasdasd",
                gameId: this.randName(),

            });


        },
        
    },

    mounted () {
        
    },

    
};


</script>

